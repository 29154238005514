import Repository from "../Repository";
import { apiConfig, company } from "../../_helpers/apiHelper";

const resource = "/settings";

export default {
  get() {
    return Repository.get(`${resource}`, apiConfig());
  },

  set(payload) {
    return Repository.post(`${resource}`, payload, apiConfig());
  },

  getGlobal() {
    return Repository.get(`${resource}/global`, apiConfig());
  },

  setGlobal(payload) {
    return Repository.post(`${resource}/global`, payload, apiConfig());
  },

  getCompanyFields() {
    return Repository.get(`${resource}/company/fields`, apiConfig());
  },

  getCompany() {
    return Repository.get(`${resource}/company/${company()}`, apiConfig());
  },

  setCompany(payload) {
    return Repository.put(
      `${resource}/company/${company()}`,
      payload,
      apiConfig()
    );
  },

  getVersions() {
    return Repository.get(`${resource}/versions`);
  },

  getIntegrationSettings() {
    return Repository.get(`${resource}/integration`, apiConfig());
  },

  createIntegrationSetting(payload) {
    return Repository.post(`${resource}/integration`, payload, apiConfig());
  },

  removeIntegrationSetting(id) {
    return Repository.delete(`${resource}/integration/${id}`, apiConfig());
  },
};
